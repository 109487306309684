export const API_ROUTES = {
  getTerms: '/terms',
  postTerms: '/terms',

  myAccount: '/my-account',
  setMyAccountData: '/my-account/my-profile',
  setUserPassword: '/my-account/set-password',
  messageMethods: '/message-methods',

  refreshToken: '/refresh',
  signIn: '/sign-in',
  signInViaToken: '/sign-in-via-token',
  worthyToken: '/worthy-token',
  signOut: '/sign-out',
  forgotPass: '/forgot-password',
  setNewPass: '/set-new-password',

  mappingDiamondsShapes: '/admin/settings/mappings/diamonds/shapes',
  mappingDiamondsColors: '/admin/settings/mappings/diamonds/color',
  mappingDiamondsClarity: '/admin/settings/mappings/diamonds/clarity',
  mappingDiamondsFluorescence: '/admin/settings/mappings/diamonds/fluorescence',
  mappingDiamondsCutPolishSymmetry: '/admin/settings/mappings/diamonds/cut-polish-symmetry',
  mappingOptionsByType: '/admin/settings/mappings/options-by-type-of-option',
  putDiamondsDictionary: '/admin/settings/mappings',

  adminGetUsers: '/admin/users',
  adminGetUserById: '/admin/users',
  adminCreateUser: '/admin/users',
  adminUpdateUser: '/admin/users',
  adminGetTokensForViewLikeAUser: '/admin/users',
  adminResendInvite: '/admin/users',
  adminGetUsersCsvTemplate: '/admin/users/csv-template',
  adminGetCategoryCsv: '/admin/users/csv-emails/category',
  adminPostUsersCsvTemplate: '/admin/users/import-csv',
  adminPostImagesMetaData: '/admin/images-meta-data',

  adminGetCompanyNS: '/admin/ns/customers',
  adminGetNSCompanyById: '/admin/ns/customers',
  adminCreateNSCompany: '/admin/ns/customers',
  adminUpdateNSCompany:'/admin/ns/customers',
  adminGetNSCountries: '/admin/ns/countries',
  adminLoadMissingCustomers: '/admin/ns/customers/load-missing',

  
  adminGetUSZips: '/admin/ns/uszips',

  categories: '/categories',
  roles: '/admin/roles',

  companies: '/admin/companies',
  getCompanyById: '/admin/companies',
  adminCreateCompany: '/admin/companies',

  adminTenders: '/admin/tenders',
  adminUpcomingTenders: '/admin/tenders/manage',
  adminCms: '/admin/cms',
  adminEmailLogs: '/admin/settings/email-logs',
  adminFilestackApiKey: '/admin/get-filestack-api-key',
  adminImageManagementDeleteAll: '/admin/gallery/delete-all-images/tenders',
  adminImageManagementDeleteOne: '/admin/gallery/delete-one-image/tenders',
  adminRecommendation: '/admin/recommendations',

  tenders: '/tenders',
  tendersDashboard: '/tenders/dashboard',

  currentTime: '/current-time',
  itemVideo: '/items/videos',

}
