import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { AppStore } from '../../store/applicationState'
import { GetAllUsers } from '../../store/admin/users/actions'
import { Button } from '../../components/ui/adminComponents/Button'
import { DropDownSimple } from '../../components/ui/adminComponents/DropDownSimple'
import { TSettingMappingOptions } from '../../store/admin/settings/mapping/types'
import { GetAllTenders } from '../../store/admin/tenders/actions'
import { TTenders } from '../../store/admin/tenders/types'
import { Table } from '../../components/ui/adminComponents/Table'
import { onPersonalRecMod } from '../../utils/userUtils/EventHandlers'
import { getStatusName } from '../../utils/tendersUtils/tenderStatusHelper'
import { SnackBar } from '../../components/ui/SnackBar'
import { GetBidsByItem } from '../../store/admin/tenders/bidsByItem/actions'
import { addToRecommendedAction } from '../../utils/galleryUtils/itemsActions'
import { SetLoader } from '../../store/globalState/action'
import { TCompanies } from '../../store/admin/companies/types'
import { GetCompanies } from '../../store/admin/companies/actions'
import { TAdminUsers } from '../../store/admin/users/types'

const tableCellWidth = [100, 150, 200, 140]

const thead = {
  shipping_owner: 'Own',
  stock_number: 'Stock #',
  title: 'Item',
  action: 'Action',
}

export const PersonalRecommendations = () => {
  const limitAmount = 1000

  const dispatch = useDispatch()
  const location = useLocation()

  const { addSnackBar } = SnackBar()
  const idTender = new URLSearchParams(location.search).get('idTender')
  const idCompany = new URLSearchParams(location.search).get('idCompany')

  const { companies, tenders, bidsByItem } = useSelector((store: AppStore) => store)

  const initialParams = {
    limit: limitAmount,
    offset: 0,
  }

  const [childIndex, setChildIndex] = useState<number[]>([])
  const [currentTender, setCurrentTender] = useState<TTenders | undefined>(undefined)
  const [currentCompany, setCurrentCompany] = useState<TCompanies | undefined>(undefined)
  const [currentUser, setCurrentUser] = useState<TAdminUsers | undefined>(undefined)
  const [update, setUpdate] = useState<number>(0)
  const [users, setUsers] = useState<TAdminUsers[]>([])
  
  const defaultCompany = useMemo(() => {
    if (!idCompany) return null
    return companies.data?.companies?.findIndex(({ id }) => idCompany && id === +idCompany)
  }, [companies.data, idCompany])

  const defaultTender = useMemo(() => {
    if (!idTender) return null
    return tenders.data?.findIndex(({ id }) => idTender && id === +idTender)
  }, [tenders.data, idTender])

  const userList = useMemo(() => {
    return users.map((user) => ({
      option_name: `${user.first_name} ${user.last_name}, ${user.email}`,
      options_group_id: user.user_id,
    }))
  }, [users])

  const getCompanyByCategory = (category: number) => {
    dispatch(
      GetCompanies.request({
        offset: initialParams.offset,
        limit: initialParams.limit,
        category,
      })
    )
  }

  useEffect(() => {
    dispatch(
      GetAllTenders.request({
        callBack: (success, data) => (success ? setCurrentTender(data?.[0]) : null),
      })
    )
  }, [])

  useEffect(() => {
    if (currentTender) getCompanyByCategory(currentTender.category_id)
  }, [currentTender])

  /// ///////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (defaultCompany) setCurrentCompany(companies.data?.companies?.[defaultCompany])
  }, [defaultCompany, companies.data])

  useEffect(() => {
    if (defaultTender)
      setCurrentTender((prev) => {
        return prev?.category_id === tenders.data?.[defaultTender].category_id ? prev : tenders.data?.[defaultTender]
      })
  }, [defaultTender, tenders.data])

  useEffect(() => {
    if (currentTender && currentCompany) {
      dispatch(SetLoader())
      dispatch(
        GetBidsByItem.request({
          idCompanyRecommendations: currentCompany.id,
          tenderId: currentTender.id,
          limit: 1000,
          offset: 0,
        })
      )
    }
  }, [currentTender, currentCompany, update])

  useEffect(() => {
    const onVisibilityChange = () => {
      if (!document.hidden) setUpdate((prev) => prev + 1)
    }

    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [])
  /// ///////////////////////////////////////////////////////////////////////////

  const addToRecommendation = (companyId: number, itemId: number) => {
    addToRecommendedAction(true, companyId, itemId, dispatch, (success) => {
      if (success) setUpdate((prev) => prev + 1)
    })
  }

  const tBody = useMemo(() => {
    if (!bidsByItem.data || !currentCompany) return null
    return bidsByItem.data.map((item) => ({
      shipping_owner: item.shipping_owner,
      stock_number: item.stock_number,
      title: item.title,
      action: (
        <button type='button' onClick={() => addToRecommendation(currentCompany?.id, item.item_id)}>
          Delete
        </button>
      ),
    }))
  }, [bidsByItem.data, currentCompany])

  const companyList = useMemo((): TSettingMappingOptions['child_options'] | null => {
    if (!companies.data?.companies) return null
    return companies.data.companies.map((company) => ({
      option_name: `${company.company_title}`,
      options_group_id: company.id,
    }))
  }, [companies.data])

  const tendersList = useMemo((): TSettingMappingOptions['child_options'] | null => {
    if (!tenders.data) return null
    return tenders.data
      .filter((tender) => getStatusName(tender) === 'active')
      .map((tender) => ({
        option_name: `${tender.sales_cycle}`,
        options_group_id: tender.id,
      }))
  }, [tenders.data])

  const onCopyLink = () => {
    navigator.clipboard
      .writeText(`${window.location.origin}/c/${currentTender?.id}`)
      .then(() => addSnackBar('Copied!', true, 'text-center !min-w-[140px]'))
  }

  const setCurrentUserByCompany = (company: TCompanies) => {
    dispatch(
      GetAllUsers.request({
        offset: 0,
        limit: 10,
        companyId: [company.id],
        categoryId: [currentTender!.category_id],
        callBack: (success, data: { users: TAdminUsers[] }) => {
          if (success) {
            const filteredUsers = data.users.filter((user) => !user.disabled)
            setUsers(filteredUsers)
            setCurrentUser(filteredUsers[0])
          }
        },
      })
    )
  }

  return (
    <>
      <div className='flex flex-col desktop:flex-row desktop:items-center gap-8 mb-4'>
        {defaultCompany !== undefined && (
          <DropDownSimple
            defaultIndex={defaultCompany || undefined}
            list={companyList}
            optionTitle='Select company'
            callBack={(item) => {
              const comp = companies.data!.companies!.find((user) => user.id === item.options_group_id)
              setCurrentCompany(comp)
              setCurrentUser(undefined)
              if (comp) setCurrentUserByCompany(comp)
            }}
            className='text-base !w-[500px]'
            withArrow
          />
        )}
        {users.length > 1 && (
          <DropDownSimple
            list={userList}
            defaultIndex={0}
            optionTitle='Select user'
            callBack={(item) => {
              const user = users.find((u) => u.user_id === item.options_group_id)
              setCurrentUser(user)
            }}
            className='text-base !w-[500px] min-w-[360px]'
            withArrow
          />
        )}
        {defaultTender !== undefined && (
          <DropDownSimple
            list={tendersList}
            defaultIndex={defaultTender || 0}
            callBack={(item) =>
              setCurrentTender((prev) => {
                const tender = tenders.data!.find((tenderValue) => tenderValue.id === item.options_group_id)
                if (tender?.id === prev?.id) return prev
                return tender
              })
            }
            optionTitle='Select tender'
            className='text-base !w-1/4'
            withArrow
          />
        )}
        <div className='flex w-full justify-between'>
          <div className=''>
            <Button
              variant='message'
              disabled={!currentCompany || !currentTender || !currentUser}
              text='Create recommendations list'
              onClick={() => onPersonalRecMod(currentUser!.user_id, dispatch, `tenders/${currentTender!.id}`)}
              image={null}
            />
          </div>
          <div className=''>
            <Button
              disabled={!currentCompany || !currentTender || !currentUser}
              variant='message'
              text='Copy link'
              image={null}
              onClick={onCopyLink}
            />
          </div>
        </div>
      </div>
      <Table
        thead={thead}
        tbody={tBody}
        tableCellWidth={tableCellWidth}
        childIndex={childIndex}
        setChildIndex={setChildIndex}
      />
    </>
  )
}
